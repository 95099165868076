<template>
  <Layout class="GameLibrary">
    <BHeader>
      <el-form
        ref="form"
        :inline="true"
        :model="formData"
        @submit.native.prevent
      >
        <el-form-item prop="userId">
          <el-input
            v-model="formData.userId"
            placeholder="UID"
            clearable
            :maxlength="19"
            type="number"
            @keyup.native="handleSearch"
          />
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="handleSearch"
          >
            搜索
          </el-button>
        </el-form-item>
      </el-form>
    </BHeader>
    <div v-if="formData.userId&&listInfo.list.content!==undefined">
      <div style="font-size: 14px;color: #606266;margin-left: 5px;">
        <div style="display:flex">
          <div style="min-width:200px;margin-left: 15px;">
            <span>UID：</span>
            <span class="left20">{{ formData.userId }}</span>
          </div>
          <div style="min-width:200px;margin-left: 15px;">
            <span>用户名：</span>
            <span class="left20">{{ userName }}</span>
          </div>
          <div style="min-width:200px;margin-left: 15px;">
            <span>国家：</span>
            <span class="left20">{{ nationCode }}</span>
          </div>
          <div style="min-width:200px;margin-left: 15px;">
            <span>游戏名：</span>
            <span class="left20">{{ listInfo.list.gameName }}</span>
          </div>
        </div>
        <div style="display:flex;margin-top:50px">
          <div style="min-width:200px;margin-left: 15px;">
            <span>版本号：</span>
            <span class="left20">{{ listInfo.list.version }}</span>
          </div>
          <div style="min-width:200px;margin-left: 15px;">
            <span>设备类型：</span>
            <span class="left20">{{ listInfo.list.platform }}</span>
          </div>
          <div style="min-width:200px;margin-left: 15px;">
            <span>最新消息时间：</span>
            <span class="left20">{{ listInfo.list.updateTime }}</span>
          </div>
        </div>
      </div>
      <div
        style="display:flex;align-items: center;margin-left: 5px;min-height:100px"
      >
        <div style="font-size: 14px;color: #606266;">
          反馈详情：
        </div>
        <div style="margin-left: 80px;font-size: 14px;color: #606266;min-width:600px">
          <div>{{ listInfo.list.content }}</div>
        </div>
      </div>

      <div
        style="display:flex;align-items: center;margin-left: 5px;min-height:80px"
      >
        <div style="font-size: 14px;color: #606266;width:100px">
          反馈图片：
        </div>
        <div style="margin-left: 30px;width:1200px;display: flex;flex-wrap: wrap;">
          <template>
            <a
              v-for="(t,i) of arrimg"
              :key="i"
              style="width: 250px; height:250px; "
              :href="t"
            >
              <el-image
                style="width: 250px; margin-left: 15px;height:250px"
                :src="t"
                fit="cover"
              >
                <div
                  slot="placeholder"
                  class="image-slot"
                >
                  <i class="el-icon-loading" />
                </div>
                <div
                  slot="error"
                  class="image-slot"
                >
                  <i class="el-icon-picture-outline" />
                </div>
              </el-image>
            </a>
          </template>
        </div>
      </div>
      <div
        style="display:flex;align-items: center;margin-left: 5px;height:90px"
      >
        <div style="font-size: 14px;color: #606266;">
          反馈视频链接：
        </div>
        <div style="margin-left: 50px;font-size: 14px;color: #606266;">
          <div>{{ listInfo.list.videoUrl }}</div>
        </div>
      </div>
      <el-form
        ref="form"
        :model="formData"
        :rules="rules"
        size="small"
        label-width="150px"
        label-position="left"
      >
        <el-form-item
          label="回复内容："
          prop="content"
        >
          <el-input
            v-model="content"
            show-word-limit
            type="textarea"
            clearable
            placeholder="多行输入"
          />
        </el-form-item>
        <el-form-item
          label="上传图片："
          prop="swiper"
        >
          <UploadImage
            :limit="9"
            :file-list.sync="swiperList"
          />
        </el-form-item>
      </el-form>
      <div style="text-align: center;">
        <el-button
          type="primary"
          :loading="listInfo.list.loading"
          :disabled="content.replace(/ /g,'') ==''&&swiperList.length<1"
          @click="sendbody"
        >
          发送
        </el-button>
      </div>
    </div>
    <div
      v-else
      style="margin-left: 50px;font-size: 14px;color: #606266;"
    >
      无用户数据
    </div>
  </Layout>
</template>

<script>
import {
  getFeedbackList,
  addFeedbackReply,
  getUserBasic
} from '@/api/Userfeedback.js'

export default {
  name: 'FeedbackSystemEdit',
  filters: {
    numberFilter (num) {
      if (num === undefined || num === null) {
        return '/'
      }
      return num
    }
  },
  data () {
    const swiperValidator = (rule, value, callback) => {
      if (this.swiperList.length) {
        return callback()
      } else {
        callback(new Error('cover is required'))
      }
    }
    return {
      rules: {
        swiper: [{ required: false, validator: swiperValidator }]
      },
      swiperList: [],
      headImageVerticalInfo: {
        data: {},
        url: '',
        isEdit: false,
        loading: false
      },
      statisticsLoading: false,
      statisticsInfo: {},
      statusList: [
        { name: '未编辑', value: 1 },
        { name: '已编辑', value: 2 }
      ],
      statusMap: {
        1: '未编辑',
        2: '已编辑'
      },
      formData: {
        userId: '',
        pageNum: 1,
        pageSize: 1
      },
      listInfo: {
        list: {},
        total: 0,
        loading: false
      },
      content: '',
      arrimg: [],
      nationCode: '',
      userName: '',
      newGameVisible: false,
      newGameLoading: false,
      newGameForm: {
        platform: 'steam',
        sourceId: ''
      }
    }
  },
  deactivated () {
    this.formData.userId = ''
    this.content = ''
    this.swiperList = []
  },
  activated () {
    if (this.$route.query.userId) {
      this.formData.userId = this.$route.query.userId
      this.formData.id = this.$route.query.id
      this.querySpiderGameList()
    } else {
      this.formData.userId = ''
      this.formData.id = ''
    }
  },
  mounted () {
    if (this.$route.query.userId) {
      this.formData.userId = this.$route.query.userId
      this.formData.id = this.$route.query.id
      this.querySpiderGameList()
    } else {
      this.formData.userId = ''
      this.formData.id = ''
    }
  },
  methods: {
    inputhandle ({ target }) {
      this.verificationVal = target.value = target.value.replace(/[^0-9]/g, '')
    },
    handleSearch () {
      this.formData.pageNum = 1
      this.formData.id = ''
      this.querySpiderGameList()
    },
    getuserinfo () {
      if (this.formData.userId !== '') {
        this.listInfo.loading = true
        getUserBasic({
          userId: this.formData.userId
        })
          .then(res => {
            if (res.code === 200) {
              this.nationCode = res.data.nationCode
              this.userName = res.data.userName
            }
          })
          .finally(() => {
            this.listInfo.loading = false
          })
      }
    },
    querySpiderGameList () {
      this.listInfo.loading = true
      getFeedbackList(this.formData)
        .then(res => {
          if (res.code === 200) {
            if (res.data.list !== null) {
              this.listInfo.list = res.data.list[0]
              this.getuserinfo()
              if (this.listInfo.list.imageUrls !== '') {
                this.arrimg = this.listInfo.list.imageUrls.split(',')
              }
            } else {
              this.listInfo.list = {}
            }
          }
        })
        .finally(() => {
          this.listInfo.loading = false
        })
    },
    sendbody () {
      this.$confirm('确定发送消息吗?', '消息发送', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          this.listInfo.loading = true
          const imagesUrlsarr = []
          for (var i = 0; i < this.swiperList.length; i++) {
            imagesUrlsarr.push(this.swiperList[i].url)
          }
          addFeedbackReply({
            adminId: this.$store.state.user.user.id,
            adminName: this.$store.state.user.user.userName,
            imagesUrls: imagesUrlsarr.join(','),
            content: this.content,
            toUserId: this.formData.userId,
            feedbackId: this.listInfo.list.id
          })
            .then(res => {
              if (res.code === 200) {
                this.$message.success('发送成功')
              }
            })
            .finally(() => {
              this.listInfo.loading = false
            })
        })
    }
  }
}
</script>

<style scoped lang="less">
  .GameLibrary {
    .overview {
      display: flex;
      // padding: 0 0 20px 0;
      min-width: 880px;
      margin-bottom: 10px;
      .items {
        width: 210px;
        height: 100px;
        box-shadow: 0 0 2px 1px rgba(0, 0, 0, .12);
        border-radius: 4px;
        margin-right: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .num {
          font-size: 26px;
          margin-bottom: 10px;
          .unit {
            font-size: 14px;
          }
        }
        .name {
          font-size: 12px;
        }
      }
    }
  }
  .left20{
    margin-left: 40px;
  }
</style>
